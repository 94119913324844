<template>
    <div class="index">
        <div style="margin: 20px auto; text-align: center; font-size: 18px;">核酸预约</div>
        <van-form @submit="onSubmit">
            <van-field
               v-model="form_data.name"
               name="name"
               label="患者姓名"
               placeholder="患者姓名"
               :rules="[{ required: true, message: '请填写患者姓名' }]"
             />
             <van-field
               v-model="form_data.tel"
               name="tel"
               label="患者电话"
               placeholder="患者电话"
               :rules="[{ required: true, message: '请填写患者电话' }]"
             />
             <van-field
               readonly
               clickable
               name="department"
               :value="form_data.department"
               label="预约科室"
               placeholder="点击选择"
               :rules="[{ required: true, message: '请填选择科室' }]"
               @click="showPicker = true"
             />
             <van-popup v-model="showPicker" position="bottom">
               <van-picker
                 show-toolbar
                 :columns="departments"
                 @confirm="onConfirm"
                 @cancel="showPicker = false"
               />
             </van-popup>

             <van-field
               readonly
               clickable
               name="date"
               :value="form_data.date"
               label="预约日期"
               placeholder="选择日期"
               @click="showPicker_date = true"
             />
             <van-calendar v-model="showPicker_date" @confirm="onConfirmDate" />

             <van-field
               v-model="form_data.time_range"
               readonly
               name="time_range"
               label="预约时段"
               placeholder="点击下方选择时段"
               :rules="[{ required: true, message: '点击下方选择时段' }]"
             />

             <van-grid :clickable="true" :gutter="5" style="margin-top: 10px;">

               <van-grid-item v-for="time_range in time_ranges" :key="time_range.key"  @click="getTimeSelect(time_range.key)" :class="{ active: time_range.key == form_data.time_range }">
                   <slot name="text">
                       <div style="font-size: 16px;">{{ time_range.key }}点</div>
                       <div style="font-size: 14px; ">余号：<strong style="color:red;">{{ time_range.num }}</strong></div>
                   </slot>
               </van-grid-item>
            </van-grid>

             <div style="margin: 35px;">
                 <van-button round block  :loading="loading" type="info" loading-text="加载中..." native-type="submit">提交</van-button>
             </div>
        </van-form>
    </div>
        
</template>
<script>
import {
    Tag,
    Col,
    Cell,
    CellGroup,
    Toast,
    DropdownMenu,
    DropdownItem,
    Button,
    Switch,
    PullRefresh,
    List,
    Form,
    Field,
    Picker,
    Popup,
    Calendar,
    Grid,
    GridItem
} from 'vant';
// import * as dd from 'dingtalk-jsapi';
import axios from 'axios'
import user from '../../mixins/user.js'
import util from '../../libs/util.js'


export default {
    components: {
        [Tag.name]: Tag,
        [Col.name]: Col,
        [Cell.name]: Cell,
        [CellGroup.name]: CellGroup,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Button.name]: Button,
        [Switch.name]: Switch,
        [PullRefresh.name]:PullRefresh,
        [List.name]:List,
        [Form.name]:Form,
        [Field.name]:Field,
        [Picker.name]:Picker,
        [Popup.name]:Popup,
        [Calendar.name]:Calendar,
        [Grid.name]:Grid,
        [GridItem.name]:GridItem
    },

    mounted() {
        console.log(this.$route)
        this.DateNow()
        
    },
    mixins: [ user ],
    data() {
        return {
            datas:[],
            isLoading: false,
            loading: false,
            finished: false,
            form_data: {
                name: '',
                tel: '',
                department: '',
                created_user: util.cookies.get('username'),
                source: '',
                date: '',
                time_range: ''
            },
            showPicker: false,
            departments:['心内','心外','神内','神外','下肢','普外','泌尿','介入','胃肠'],
            showPicker_date: false,
            time_ranges: []
        };
    },
    
    methods: {

        onConfirm(value) {
            this.form_data.department = value;
            this.showPicker = false;
        },
        onConfirmDate(date) {
            let d = this.formatDate(date)
            this.form_data.date =  d;


            this.showPicker_date = false;
        },

        getTimeSelect(val) {
            this.form_data.time_range = val
        },

        onSubmit(values) {
            console.log(values)
            this.loading = true;
            axios.post('https://ydwx.83277777.com/api/bookhs/store', values).then(res => {
                    if (res.data.success) {
                        Toast.success(res.data.message);

                        this.form_data['name'] = '';
                        this.form_data['tel'] = '';
                        this.form_data['department'] = '';
                        this.formatDate(this.form_data['date'])

                    } else {
                        Toast.fail(res.data.message);
                    }
                    this.loading = false
                }).catch((e) => {
                    console.log(e)
                    this.loading = false
                })
        },

        DateNow() {
            var d = new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            this.form_data.date = [year, month, day].join('-');
        },

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        getTimeBookStatus(date) {

            Toast.loading({duration: 0});

            axios.post('https://ydwx.83277777.com/api/bookhs/getBookCount', {date:date}).then(res => {
                    this.time_ranges = res.data.data
                    this.loading = false
                    Toast.clear()
                }).catch((e) => {
                    console.log(e)
                })
        },

        onRefresh() {
            Toast('刷新成功');
            this.isLoading = false;
        },
        onLoad() {
            this.loading = false
            console.log('loading')
        },
        sorry() {
            Toast('暂无后续逻辑~');
        }
    },
    watch: {
        'form_data.date': function(val) {
            this.getTimeBookStatus(val)
        }
    }
};
</script>
<style lang="less">
    .active {
        .van-grid-item__content {
            background-color: rgb(217, 236, 255);
        }
        
    }
</style>